<template>
	<main class="auth-main">

		<div class="absolute left-0 top-0 h-16 px-5 flex items-center">
			<router-link to="/">
				<h1>DinAnmälan</h1>
			</router-link>
		</div>

		<div class="auth">
			<div class="auth-left"></div>


			<div class="auth-right">
				<h2 class="mb-20">Logga in</h2>

				<form @submit.prevent="login">
					<BaseInput type="text" v-model="user.username" class="auth-input">
						E-post eller personnummer
					</BaseInput>

					<div class="w-2/3 mb-5">
						<BaseInput type="password" v-model="user.password">
							Lösenord
						</BaseInput>
						<router-link to="/lost_password" class="forgot-password-link">
							Har du glömt ditt lösenord?
						</router-link>
					</div>

					<BaseLinkButton to="/" class="auth-submit mr-5" transparent>
						Tillbaka
					</BaseLinkButton>

					<BaseButton type="submit" class="auth-submit mr-5">
						Logga in
					</BaseButton>

					<p class="mt-2">
						Har du inte ett konto?
						<router-link to="/register" class="underline text-primary">Registrera dig här</router-link>
					</p>
				</form>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: 'Login',
	data: () => ({
		user: {
			username: '',
			password: '',
		},
	}),
	methods: {
		login() {
			this.$store.dispatch('AuthStore/login', this.user)
		},
	},
}
</script>

<style scoped>
.forgot-password-link {
	@apply block mt-2 text-primary text-xs text-right;
}
</style>
